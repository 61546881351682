export const routes = {
	login: '/login',
	home: '/',
	detailPage: '/detailPage',
	detailPageById: '/detailPage/:id',
	editComponent: '/component/:id/edit',
	createComponent: '/component/new',
	components: '/components',
	createPage: '/createPage',
	documents: '/documents',
	promoPages: '/promo-pages',
	variables: '/variables',
	pageTexts: '/page-texts',
	editPageTextById: '/page-texts/:id/edit',
	createPageText: '/page-texts/create',
}
