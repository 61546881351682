import { lazy, Suspense } from 'react'
import { Route,Switch } from 'react-router-dom'
import { PageLayout } from 'components/layouts/PageLayout'
import { UserInfo } from 'components/user-info'
import { AppEventsConsumer } from 'events/consumer'
import { QueryProvider } from 'features/query'
import type { KeycloakResourceAccess } from 'keycloak-js'
import { ConfirmDialogProvider } from 'providers/confirm-dialog'
import { NotificationsProvider } from 'providers/notifications'
import { ThemeProvider } from 'theme/provider'
import { requireNotNull } from 'utils'

import { routes } from '../routes'

import { keycloak, KeycloakProvider } from './keycloak'

import 'jsoneditor-react/es/editor.min.css'

const PagesList = lazy(async () => import('pages/pages'))
const DetailPage = lazy(async () => import('pages/detail-page'))
const ComponentsList = lazy(async () => import('pages/components'))
const CreatePage = lazy(async () => import('pages/createPage'))
const PageTexts = lazy(async () => import('pages/pageTexts'))
const EditPageText = lazy(async () => import('pages/pageTexts/edit'))
const CreatePageText = lazy(async () => import('pages/pageTexts/create'))
const EditComponentPage = lazy(async () => import('pages/editComponent'))
const NotFound = lazy(async () => import('pages/not-found'))
const CreateComponent = lazy(async () => import('pages/createComponent'))
const DocumentsPage = lazy(async () => import('pages/DocumentsPage'))
const PromoPagesPage = lazy(async () => import('pages/promoPages'))
const VariablesPage = lazy(async () => import('pages/variables'))

function Fallback() {
	const text = 'loading...'
	return <PageLayout title={text}>{text}</PageLayout>
}

const isValidAccessScope = (resourceAddresses?: KeycloakResourceAccess) => {
	if (!resourceAddresses) {
		return false
	}
	const scope = requireNotNull(window.__env__.KEYCLOAK_CLIENT_ID)
	return resourceAddresses[scope] && resourceAddresses[scope].roles.includes('access')
}

const isIgnoreKeycloak = window.__env__.IGNORE_KEYCLOAK === 'true'

function AppContent() {
	return (
		<QueryProvider>
			<ThemeProvider>
				<ConfirmDialogProvider>
					<NotificationsProvider>
						<AppEventsConsumer />
						{!isIgnoreKeycloak && <UserInfo />}
						<Suspense fallback={<Fallback />}>
							<Switch>
								<Route exact path={routes.home} component={PagesList as any} />
								<Route exact path={routes.components} component={ComponentsList as any} />
								<Route exact path={routes.documents} component={DocumentsPage as any} />
								<Route exact path={routes.createPage} component={CreatePage as any} />
								<Route exact path={routes.pageTexts} component={PageTexts as any} />
								<Route exact path={routes.editPageTextById} component={EditPageText as any} />
								<Route exact path={routes.createPageText} component={CreatePageText as any} />
								<Route exact path={routes.detailPageById} component={DetailPage as any} />
								<Route exact path={routes.editComponent} component={EditComponentPage as any} />
								<Route exact path={routes.createComponent} component={CreateComponent as any} />
								<Route exact path={routes.promoPages} component={PromoPagesPage as any} />
								<Route exact path={routes.variables} component={VariablesPage as any} />
								<Route path='*' component={NotFound as any} />
							</Switch>
						</Suspense>
					</NotificationsProvider>
				</ConfirmDialogProvider>
			</ThemeProvider>
		</QueryProvider>
	)
}

function AppKeycloakResolver() {
	if (!keycloak.authenticated) {
		return <div>access denied</div>
	}

	if (!isValidAccessScope(keycloak.tokenParsed?.resource_access)) {
		return (
			<div>
				access denied <br />
				<div
					style={{ textDecoration: 'underline', cursor: 'pointer' }}
					onClick={() => keycloak.logout()}
					tabIndex={0}
				>
					try to another account
				</div>
			</div>
		)
	}

	return <AppContent />
}

// const eventLogger = (event: unknown, error: unknown) => {
// 	if (!isDebug) {
// 		return
// 	}
// 	console.log('onKeycloakEvent', event, error)
// }

export function App() {
	if (isIgnoreKeycloak) {
		return <AppContent />
	}

	return (
		<KeycloakProvider>
			<AppKeycloakResolver />
		</KeycloakProvider>
	)
}
